import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Flex, } from "antd";
import header_background from "../../../assets/img/header_code.jpeg";
import logo from "../../../assets/img/logo.png";

import LogoGather from "./HeaderAnimation.js";
import FixedBar from "./FixedBar.js";


function PageHeader() {
  const images = require.context("../../../assets/img/languages", true);
  const imageList = images.keys().map((image) => images(image));
  const corte = Math.round(imageList.length / 2);
  let imgSet1 = [];
  let imgSet2 = [];

  for (var i = 0; i < imageList.length; i++) {
    if (i < corte) {
      imgSet1.push(imageList[i]);
    } else {
      imgSet2.push(imageList[i]);
    }
  }

  const [width, setWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  });

  const renderBottomHeader = () => {
    return isMobile() ? (
      <Row style={{ height: "100%" }}>
        <Col span={8} offset={8} style={{ height: "100%" }}>
          <Flex
            flex={1}
            vertical
            justify="center"
            align="center"
            style={{ height: "100%" }}
          >
            <Avatar
              src={logo}
              shape="circle"
              size={100}
              style={{
                backgroundColor: "#ffffff",
                border: "solid 1px #000000",
              }}
            />
            <div
              style={{
                marginTop: 30,
                padding: 7,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.6rem",
                  fontWeight: "bolder",
                  color: "#FFFFFF",
                  fontStyle: "italic",
                }}
              >
                Desenvolvimento de Software
              </div>
              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.6rem",
                  fontWeight: "bolder",
                  color: "#FFFFFF",
                  fontStyle: "italic",
                }}
              >
                Design de Sites
              </div>
              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.6rem",
                  fontWeight: "bolder",
                  color: "#FFFFFF",
                  fontStyle: "italic",
                }}
              >
                Consultoria
              </div>
            </div>
          </Flex>
        </Col>
      </Row>
    ) : (
      <Row style={{ height: "100%" }}>
        <Col span={8}  style={{ height: "100%" }}>
          <Flex
            flex={1}
            gap={10}
            wrap="wrap"
            justify="center"
            align="center"
            style={{ height: "100%" }}
          >
            {imgSet1.map((img) => {
              return (
                <Avatar
                  src={img}
                  style={{
                    width: "4vw",
                    height: "4vw",
                    backgroundColor: "white",
                  }}
                />
              );
            })}
          </Flex>
        </Col>
        <Col span={8}  style={{ height: "100%" }}>
          <Flex
            flex={1}
            vertical
            justify="center"
            align="center"
            style={{ height: "100%" }}
          >
            <Avatar
              src={logo}
              shape="circle"
              size={100}
              style={{
                backgroundColor: "#ffffff",
                border: "solid 1px #000000",
              }}
            />
            <div
              style={{
                marginTop: 30,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.6rem",
                  fontWeight: "bolder",
                  color: "#FFFFFF",
                  fontStyle: "italic",
                }}
              >
                Desenvolvimento de Software
              </div>
              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.6rem",
                  fontWeight: "bolder",
                  color: "#FFFFFF",
                  fontStyle: "italic",
                }}
              >
                Design de Sites
              </div>
              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.6rem",
                  fontWeight: "bolder",
                  color: "#FFFFFF",
                  fontStyle: "italic",
                }}
              >
                Consultoria
              </div>
            </div>
          </Flex>
        </Col>
        <Col span={8}  style={{ height: "100%" }}>
          <Flex
            flex={1}
            gap={10}
            wrap="wrap"
            justify="center"
            align="center"
            style={{ height: "100%" }}
          >
            {" "}
            {imgSet2.map((img) => {
              return (
                <Avatar
                  src={img}
                  style={{
                    width: "4vw",
                    height: "4vw",
                    backgroundColor: "white",
                  }}
                />
              );
            })}
          </Flex>
        </Col>
      </Row>
    );
  };

  const isMobile = () => (width < 750 ? true : false);
  return (
    <Row onLoad={goToStart}>
      <FixedBar />
      <Col id="first_obj" style={{ height: "5vh", width: "100vw" }} />
      <Col>
        <div style={{ width: "100vw", height: "50vh" }}>
          <LogoGather></LogoGather>
        </div>
      </Col>
      <Col>
        <Flex
          vertical
          flex={1}
          style={{
            width: "100vw",
            height: "45vh",
            backgroundColor: "#000000",
            backgroundImage: `url(${header_background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {renderBottomHeader()}
        </Flex>
      </Col>
    </Row>
  );
}

function goToStart() {
  const element = document.getElementById("first_obj");
  element.scrollIntoView({ behavior: "smooth", block: "start" });
}

export default PageHeader;
