import {
  Layout,
  Col,
  Row,
  Anchor,
  Button,
  Card,
  Flex,
  Space,
  Avatar,
  Image,
} from "antd";
import Meta from "antd/lib/card/Meta";
import {
  ConsoleSqlOutlined,
  CodeOutlined,
  TeamOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { Fade } from "react-reveal";
import Carousel from "./Carousel";
import "./home.css";
import imgDesenvolvimento from "../../assets/img/Desenvolvimento.webp";
import imgMigracao from "../../assets/img/MigracaoSoftware.jpg";
import imgSuporte from "../../assets/img/Suporte.jpg";
import imgPrestacaoServicos from "../../assets/img/PrestacaoServicos.jpg";
import imgHome1 from "../../assets/img/Home_1.jpeg";
import { Component, useEffect, useState } from "react";
import { Class } from "@mui/icons-material";
import React from "react";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ width: window.innerWidth })
    );
    return () => {
      window.removeEventListener("resize", () =>
        this.setState({ width: window.innerWidth })
      );
    };
  }

  //Introdução
  HomeIntroduction = () => {
    const isMobile = () => (this.state.width < 750 ? true : false);
    return isMobile() ? (
      <Row>
        <Col span={24}>
          <div style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
            Layons é uma empresa que está pronta para desenvolver projetos com
            você e te acompanhar na sua jornada com todo o suporte que precisa.
            <br />
            Com uma Equipe especializada em desenvolvimento de ERP, GR,
            Logística, CRM, Sites e Apps.
          </div>
          <p style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
            Contamos com desenvolvedores especializados em Dataflex Web,
            Dataflex Desktop e tecnologias atuais de mercado como Node, Kotlin,
            Swift, Java e mais.
            <br />
            Trabalhamos com Frameworks para agilizar o desenvolvimento do
            cliente com qualidade.
            <br />
            Os Frameworks mais usados seriam Bootstrap, React, React-Native,
            JQuery, Babel.
            <br />
            Estamos sempre à disposição de escutar as preferências do cliente em
            um desenvolvimento para que assim ele fique contente com o
            resultado.
          </p>
          <p style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
            Desenvolvimento responsivo e compatível com 100% dos aparelhos
            usados atualmente.
            <br />
            Utilizamos de ferramentas capazes de fazer o seu sistema ser
            compatível tanto para web quanto para celular.
          </p>
          <hr />
        </Col>
      </Row>
    ) : (
      <Row>
        <Col span={10}>
          <div style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
            Layons é uma empresa que está pronta para desenvolver projetos com
            você e te acompanhar na sua jornada com todo o suporte que precisa.
            <br />
            Com uma Equipe especializada em desenvolvimento de ERP, GR,
            Logística, CRM, Sites e Apps.
          </div>
          <p style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
            Contamos com desenvolvedores especializados em Dataflex Web,
            Dataflex Desktop e tecnologias atuais de mercado como Node, Kotlin,
            Swift, Java e mais.
            <br />
            Trabalhamos com Frameworks para agilizar o desenvolvimento do
            cliente com qualidade.
            <br />
            Os Frameworks mais usados seriam Bootstrap, React, React-Native,
            JQuery, Babel.
            <br />
            Estamos sempre à disposição de escutar as preferências do cliente em
            um desenvolvimento para que assim ele fique contente com o
            resultado.
          </p>
          <p style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
            Desenvolvimento responsivo e compatível com 100% dos aparelhos
            usados atualmente.
            <br />
            Utilizamos de ferramentas capazes de fazer o seu sistema ser
            compatível tanto para web quanto para celular.
          </p>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <Image src={imgHome1} preview={false} />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>
        <div className="home_container">
          <Fade bottom>
            <Row>
              <Col span={18} offset={3}>
                <Row>
                  <Col span={24}>
                    <h1
                      style={{
                        textAlign: "center",
                        color: "CaptionText",
                        fontFamily: "flowrise",
                      }}
                    >
                      Desenvolva o seu sonho conosco
                    </h1>
                    <br />
                  </Col>
                </Row>
                {this.HomeIntroduction()}
              </Col>
            </Row>
          </Fade>
          <div style={{ width: "100%", margin: 50 }} />
          <Fade bottom>
            <Row>
              <Col span={18} offset={3}>
                <Flex flex={1} wrap="wrap" gap={10} justify="space-between">
                  <Card
                    style={{ width: 300 }}
                    cover={
                      <img
                        alt="Prestação de Serviços"
                        src={imgPrestacaoServicos}
                      />
                    }
                  >
                    <Meta
                      avatar={
                        <Avatar
                          style={{ backgroundColor: "#e74c3c" }}
                          icon={<ConsoleSqlOutlined />}
                        />
                      }
                      title="Prestação de Serviços"
                      description="Ciclo completo de desenvolvimento, da infraestrutura ao Site ou Software costumizado"
                    />
                  </Card>
                  <Card
                    style={{ width: 300 }}
                    cover={
                      <img
                        alt="Desenvolvimento Especializado"
                        src={imgDesenvolvimento}
                      />
                    }
                  >
                    <Meta
                      avatar={
                        <Avatar
                          style={{ backgroundColor: "#2ecc71" }}
                          icon={<CodeOutlined />}
                        />
                      }
                      title="Desenvolvimento"
                      description="Trabalhamos com desenvolvimento de Aplicativos, Softwares e Sites personalizados para atender o cliente com componentes costumizáveis"
                    />
                  </Card>

                  <Card
                    style={{ width: 300 }}
                    cover={<img alt="Suporte" src={imgSuporte} />}
                  >
                    <Meta
                      avatar={
                        <Avatar
                          style={{ backgroundColor: "#3498db" }}
                          icon={<TeamOutlined />}
                        />
                      }
                      title="Suporte"
                      description="Garantia na sua operação com SLA para atender da melhor forma no suporte"
                    />
                  </Card>

                  <Card
                    style={{ width: 300 }}
                    cover={<img alt="Consultoria" src={imgMigracao} />}
                  >
                    <Meta
                      avatar={
                        <Avatar
                          style={{ backgroundColor: "#34495e" }}
                          icon={<ApartmentOutlined />}
                        />
                      }
                      title="Consultoria"
                      description="Consultoria em software e migração de softwares legado para um melhor desempenho nos servidores, Consultoria na para empresa na LGPD"
                    />
                  </Card>
                </Flex>
              </Col>
            </Row>
          </Fade>
        </div>
        <Fade>
          <Carousel />
        </Fade>
      </div>
    );
  }
}

export default HomePage;
