import { useContext } from "react";
import { QRCode, Avatar, Button, Col, Flex, Row } from "antd";
import {
  LinkedinOutlined,
  GithubOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import iconReact from "../../../assets/img/languages/react.png";
import "./PageFooter.css";

//TEMPLATES
import HomePage from "../../../templates/home"
import SobrePage from "../../../templates/sobre"
import ContatoPage from "../../../templates/contato"

//CONTEXT
import { ContextContainer } from "../../context/containerRender";

function PageFooter() {
  const { pageRender, setPageRender } = useContext(ContextContainer);

  function goToContainer (componente) {
    const element = document.getElementById("Container");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  
    setPageRender(componente)
  }

  return (
    <div className="footerPanel">
      <Row
        justify="space-around"
        style={{ height: "80%", marginTop: "10px", marginBottom: "10px" }}
      >
        <Col span={4} className="flexHeight"></Col>
        <Col span={6} className="flexHeight">
          <Flex
            flex={1}
            align="flex-start"
            justify="center"
            vertical
            className="flexHeight"
          >
            <Button type="text" className="footerItems" onClick={()=>goToContainer(<HomePage/>)}>
              Home
            </Button>
            <Button type="text" className="footerItems" onClick={()=>goToContainer(<SobrePage/>)}>
              Sobre
            </Button>
            <Button type="text" className="footerItems" onClick={()=>goToContainer(<ContatoPage/>)}>
              Contato
            </Button>
          </Flex>
        </Col>
        <Col span={6}>
          <Flex>
            <Flex
              flex={1}
              gap={10}
              align="flex-end"
              justify="center"
              vertical
              className="flexHeight"
            >
              <Button
                type="default"
                shape="circle"
                icon={<LinkedinOutlined />}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/daniel-layon-07a613182/",
                    "_blank",
                    "noreferrer"
                  )
                }
              ></Button>
              <Button
                type="default"
                shape="circle"
                icon={<GithubOutlined />}
                onClick={() =>
                  window.open(
                    "https://github.com/DanielLayon",
                    "_blank",
                    "noreferrer"
                  )
                }
              ></Button>
              <Button
                type="default"
                shape="circle"
                icon={<WhatsAppOutlined />}
                onClick={() =>
                  window.open(
                    "https://github.com/DanielLayon",
                    "_blank",
                    "noreferrer"
                  )
                }
              ></Button>
            </Flex>
            <Flex
              flex={1}
              gap={10}
              align="center"
              justify="center"
              className="flexQRCODE"
            >
              <div className="QRCODE_Container">
                <QRCode
                  size={130}
                  errorLevel="H"
                  value="https://ant.design/"
                  icon={<WhatsAppOutlined />}
                />
              </div>
            </Flex>
          </Flex>
        </Col>
        <Col span={6}></Col>
      </Row>
      <Row style={{ height: "20%" }}>
        <Col span={14} offset={4}>
          <Flex
            flex={1}
            vertical
            justify="center"
            align="center"
            style={{ padding: "10px", borderTop: "solid 1px #0e0e0f" }}
          >
            <span className="footerMade">
              Criado em React <Avatar size={15} src={iconReact} />
            </span>
            <span className="footerBy">
              ©2023 Layons. Todos os direitos reservados
            </span>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

function goToContainer() {
  const element = document.getElementById("Container");
  element.scrollIntoView({ behavior: "smooth", block: "start" });
}

export default PageFooter;
