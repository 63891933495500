import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import PageHeader from "../components/layouts/header/PageHeader.js";
import PageFooter from "../components/layouts/footer/PageFooter.js";
import HomePage from "./home/index.js";
import { ContextContainer } from "../components/context/containerRender.js";
import { useState } from "react";

function Main() {
  const [pageRender, setPageRender] = useState(<HomePage />);
  return (
    <ContextContainer.Provider value={{ pageRender, setPageRender }}>
      <Layout>
        <PageHeader />
        <Layout>
          <Content id="Container">{pageRender}</Content>
        </Layout>
        <PageFooter />
      </Layout>
    </ContextContainer.Provider>
  );
}

export default Main;
