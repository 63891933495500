import {
  Layout,
  Col,
  Row,
  Anchor,
  Button,
  Card,
  Flex,
  Space,
  Avatar,
  Image,
  Divider,
} from "antd";
import Meta from "antd/lib/card/Meta";
import {
  ConsoleSqlOutlined,
  CodeOutlined,
  TeamOutlined,
  ApartmentOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Fade } from "react-reveal";
import { Component, useContext, useEffect, useState } from "react";
import React from "react";

import ContatoPage from "../contato";

//ASSETS IMAGES
import imgDesenvolvimento from "../../assets/img/Desenvolvimento.webp";
import imgMigracao from "../../assets/img/MigracaoSoftware.jpg";
import imgSuporte from "../../assets/img/Suporte.jpg";
import imgPrestacaoServicos from "../../assets/img/PrestacaoServicos.jpg";

//CONTEXT
import { ContextContainer } from "../../components/context/containerRender";

function SobrePage() {
  const [ width, setWidth ] = useState(window.innerWidth);
  const { pageRender, setPageRender } = useContext(ContextContainer);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
  });

  return (
    <div>
      <Fade bottom>
        <Row>
          <Col span={18} offset={3}>
            <Row>
              <Col span={24}>
                <h1
                  style={{
                    textAlign: "center",
                    color: "CaptionText",
                    fontFamily: "flowrise",
                  }}
                >
                  Sobre
                </h1>
                <br />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
                  Nosso objetivo é poder atuar junto com empresas para que elas
                  se desenvolvam em tecnologia.
                  <br />
                  Hoje a maior parte das empresas acabam não conseguindo se
                  desenvolver ou agilizar seus processos por falta de sistemas
                  ou não conseguem ser vistos por não atuarem na internet com
                  captação de novos clientes.
                  <br />
                  Queremos que esssas empresas possam ser vistas e assim podendo
                  progredir .
                </div>
                <p style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
                  Estamos acostumados com os processos de GR, ERP por fazerem
                  parte da formação da Layons.
                  <br />
                  Familiarizados com os processos de Compras, Cotação, PCP,
                  Financeiro e mais dentro de um sistema ERP.
                  <br />
                  Familiarizados com as tecnologias de monitoramento como
                  Sascar, Omnilink, Onix, Sighra, Autotrac e outras tecnologias
                  <br />
                  Desenvolvimento de API, REST FULL, SOAP, RabbitMQ, Socket e
                  mais.
                </p>
                <p style={{ fontSize: "1.2rem", fontFamily: "cursive" }}>
                  Personalização faz parte da nossa maior qualidade, com o
                  cliente podendo participando de todo processo assim criando um
                  produto que ele fez parte.
                  <br />
                  Animações com Ant Motion para que tenha um site único
                </p>
              </Col>
              <Divider />
            </Row>
          </Col>
        </Row>
      </Fade>
      <Fade bottom>
        <Row style={{ width: "100%" }}>
          <Col span={20} offset={2} style={{ width: "100%" }}>
            <Flex
              flex={1}
              justify="space-evenly"
              wrap="wrap"
              gap={10}
              style={{ width: "100%" }}
            >
              <Card
                style={{ width: 300 }}
                cover={
                  <img alt="Prestação de Serviços" src={imgPrestacaoServicos} />
                }
              >
                <Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: "#e74c3c" }}
                      icon={<ConsoleSqlOutlined />}
                    />
                  }
                  title="Prestação de Serviços"
                  description="Ciclo completo de desenvolvimento, da infraestrutura ao Site ou Software costumizado"
                />
              </Card>
              <Card
                style={{ width: 300 }}
                cover={
                  <img
                    alt="Desenvolvimento Especializado"
                    src={imgDesenvolvimento}
                  />
                }
              >
                <Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: "#2ecc71" }}
                      icon={<CodeOutlined />}
                    />
                  }
                  title="Desenvolvimento"
                  description="Trabalhamos com desenvolvimento de Aplicativos, Softwares e Sites personalizados para atender o cliente com componentes costumizáveis"
                />
              </Card>

              <Card
                style={{ width: 300 }}
                cover={<img alt="Suporte" src={imgSuporte} />}
              >
                <Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: "#3498db" }}
                      icon={<TeamOutlined />}
                    />
                  }
                  title="Suporte"
                  description="Garantia na sua operação com SLA para atender da melhor forma no suporte"
                />
              </Card>

              <Card
                style={{ width: 300 }}
                cover={<img alt="Consultoria" src={imgMigracao} />}
              >
                <Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: "#34495e" }}
                      icon={<ApartmentOutlined />}
                    />
                  }
                  title="Consultoria"
                  description="Consultoria em software e migração de softwares legado para um melhor desempenho nos servidores, Consultoria na para empresa na LGPD"
                />
              </Card>
            </Flex>
          </Col>
        </Row>
      </Fade>
      <Divider />
      <Fade bottom>
        <Row>
          <Col span={18} offset={3}>
            <Flex flex={1} justify="center" align="center">
              <Button
                type="primary"
                size="large"
                shape="round"
                icon={<RightCircleOutlined />}
                onClick={() => {
                  setPageRender(<ContatoPage />);
                }}
              >
                Saber Mais...
              </Button>
            </Flex>
          </Col>
        </Row>
      </Fade>
      <Divider />
    </div>
  );
}

export default SobrePage;
