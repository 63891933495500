import {
    Body,
    Button,
    Container,
    Head,
    Hr,
    Html,
    Img,
    Link,
    Preview,
    Row,
    Section,
    Text,
  } from '@react-email/components';
  import * as React from 'react';
  import logo from "../../assets/img/logo.png"
  
  const baseUrl = process.env.VERCEL_URL
    ? `https://layons.net/}`
    : '';
  
  const ContatoLayons = ({
    nome = 'NULL',
    assunto = `“NULL”`,
    descricao = `“NULL”`,
    email = "NULL",
    celular = "NULL"
  } ) => {
    const previewText = `Contato de ${nome}`;
  
    return (
      <Html>
        <Head />
        <Preview>{previewText}</Preview>
  
        <Body style={main}>
          <Section style={main}>
            <Container style={container}>
              <Section>
                {/* <Img
                  src={logo}
                  width="96"
                  height="30"
                  alt="Layons"
                /> */}
              </Section>
              <Section>
                {/* <Img
                  src={logo}
                  width="96"
                  height="96"
                  alt={nome}
                  style={userImage}
                /> */}
              </Section>
              <Section style={{ paddingBottom: '20px' }}>
                <Row>
                  <Text style={heading}>{nome} entrou em contato para falar "{assunto}"</Text>
                  <Text style={review}>{descricao}</Text>
                  <Text style={paragraph}>
                    Meu email é {email} e meu celular é {celular}
                  </Text>  
                </Row>
              </Section>
  
              <Hr style={hr} />
  
              <Section>
                <Row>
                  <Text style={footer}>
                    Layons Desenvolvimento de Qualidade
                  </Text>
                  <Link href="https://layons.net" style={reportLink}>
                    Veja nosso Site!
                  </Link>
                </Row>
              </Section>
            </Container>
          </Section>
        </Body>
      </Html>
    );
  };
  
  export default ContatoLayons;
  
  const main = {
    backgroundColor: '#ffffff',
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  };
  
  const container = {
    margin: '0 auto',
    padding: '20px 0 48px',
    width: '580px',
  };
  
  const userImage = {
    margin: '0 auto',
    marginBottom: '16px',
    borderRadius: '50%',
  };
  
  const heading = {
    fontSize: '32px',
    lineHeight: '1.3',
    fontWeight: '700',
    color: '#484848',
  };
  
  const paragraph = {
    fontSize: '18px',
    lineHeight: '1.4',
    color: '#484848',
  };
  
  const review = {
    ...paragraph,
    padding: '24px',
    backgroundColor: '#f2f3f3',
    borderRadius: '4px',
  };
  
  const button = {
    backgroundColor: '#ff5a5f',
    borderRadius: '3px',
    color: '#fff',
    fontSize: '18px',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'block',
    width: '100%',
  };
  
  const link = {
    ...paragraph,
    color: '#ff5a5f',
    display: 'block',
  };
  
  const reportLink = {
    fontSize: '14px',
    color: '#9ca299',
    textDecoration: 'underline',
  };
  
  const hr = {
    borderColor: '#cccccc',
    margin: '20px 0',
  };
  
  const footer = {
    color: '#9ca299',
    fontSize: '14px',
    marginBottom: '10px',
  };