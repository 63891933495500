import { Resend } from "resend";
import ContatoLayons from "../../components/email/Email.js";
import {
  Layout,
  Col,
  Row,
  Anchor,
  Button,
  Card,
  Flex,
  Space,
  Avatar,
  Image,
  Input,
  Form,
  Divider,
  InputNumber,
  Alert,
} from "antd";
import Meta from "antd/lib/card/Meta";
import {
  ConsoleSqlOutlined,
  CodeOutlined,
  TeamOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { Fade } from "react-reveal";
import { useContext, useEffect, useState } from "react";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import { EmailJSResponseStatus, send } from "@emailjs/browser";

function ContatoPage() {
  const [width, setwidth] = useState(window.innerWidth);
  const [nome, setnome] = useState("");
  const [email, setemail] = useState("");
  const [ddd, setddd] = useState("");
  const [celular, setcelular] = useState("");
  const [assunto, setassunto] = useState("");
  const [descricao, setdescricao] = useState("");
  const [isEmailSended, setIsEmailSended] = useState(false);
  const [isEnvioEmailBloqueado, setIsEnvioEmailBloqueado] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => setwidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setwidth(window.innerWidth));
    };
  });

  const onFinish = () => {
    try {
      send(
        "service_a54tbue",
        "template_zj8yz8g",
        {
          reply_to: "contato@layons.net",
          nome: nome,
          email: email,
          celular: ddd.toString() + celular.toString(),
          assunto: assunto,
          descricao: descricao,
        },
        "G_NWWukFcwhZM5Qxl"
      )
        .then((e) => {
          setIsEmailSended(true);
          setIsEnvioEmailBloqueado(true);
        })
        .catch((e) => {});
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <Divider />
      <Fade>
        <Row>
          <Col span={10} offset={8}>
            <Card title="Contato">
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Nome"
                  name="Nome"
                  rules={[
                    {
                      required: true,
                      message: "Informe o seu nome",
                    },
                  ]}
                >
                  <Input
                    placeholder="Nome"
                    onChange={(e) => setnome(e.target.value)}
                    maxLength={100}
                  />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: "Informe o seu email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    onChange={(e) => setemail(e.target.value)}
                    maxLength={100}
                  />
                </Form.Item>

                <Form.Item
                  label="DDD"
                  name="DDD"
                  rules={[
                    {
                      required: true,
                      message: "Informe o seu DDD",
                    },
                  ]}
                >
                  <InputNumber
                    onChange={(e) => setddd(e)}
                    placeholder="DDD"
                    maxLength={3}
                  />
                </Form.Item>

                <Form.Item
                  label="Celular"
                  name="Celular"
                  rules={[
                    {
                      required: true,
                      message: "Informe o seu Celular",
                    },
                  ]}
                >
                  <InputNumber
                    onChange={(e) => setcelular(e)}
                    placeholder="Celular"
                    maxLength={15}
                  />
                </Form.Item>

                <Form.Item
                  label="Assunto"
                  name="Assunto"
                  rules={[
                    {
                      required: true,
                      message: "Informe o assunto",
                    },
                  ]}
                >
                  <Input
                    placeholder="Assunto"
                    onChange={(e) => setassunto(e.target.value)}
                    maxLength={30}
                  />
                </Form.Item>

                <Form.Item
                  label="Descrição"
                  name="Descrição"
                  rules={[
                    {
                      required: true,
                      message: "Informe a descrição",
                    },
                  ]}
                >
                  <TextArea
                    onChange={(e) => setdescricao(e.target.value)}
                    rows={4}
                    placeholder="Limite de 500"
                    maxLength={500}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    disabled={isEnvioEmailBloqueado}
                    type="primary"
                    htmlType="submit"
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Divider />
          <Col span={10} offset={8}>
            {isEmailSended ? (
              <div>
                <Alert
                  message="Sucesso"
                  description="Email enviado com sucesso."
                  type="success"
                  closable
                  showIcon
                />
                <hr />
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Fade>
    </div>
  );
}

export default ContatoPage;
