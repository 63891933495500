import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { Avatar, Button, Dropdown, Flex } from "antd";
import {
  LinkedinOutlined,
  GithubOutlined,
  WhatsAppOutlined,
  MenuOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import "./FixedBar.css";

//ASSETS IMAGES
import logo from "../../../assets/img/logo.png";

//TEMPLATES
import HomePage from "../../../templates/home"
import SobrePage from "../../../templates/sobre"
import ContatoPage from "../../../templates/contato"

//CONTEXT
import { ContextContainer } from "../../context/containerRender";

export default function () {
  const flexHeader = {
    height: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
  };

  const [width, setWidth] = useState(window.innerWidth);
  const { pageRender, setPageRender } = useContext(ContextContainer);

  function goToContainer (componente) {
    const element = document.getElementById("Container");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  
    setPageRender(componente)
  }

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  });

  const itemsMobile = [
    {
      key: "1",
      label: (
        <a type="text" onClick={()=>goToContainer(<HomePage/>)}>
          Home
        </a>
      ),
      icon: <HomeOutlined />,
    },
    {
      key: "2",
      label: (
        <a type="text" onClick={()=>goToContainer(<SobrePage/>)}>
          Sobre
        </a>
      ),
      icon: <InfoCircleOutlined />,
    },
    {
      key: "3",
      label: (
        <a type="text" onClick={()=>goToContainer(<ContatoPage/>)}>
          Contato
        </a>
      ),
      icon: <MessageOutlined />,
    },
    {
      type: "divider",
    },
    {
      key: "4",
      label: (
        <Flex gap={2} justify="space-around">
          <Button
            type="default"
            shape="circle"
            icon={<LinkedinOutlined />}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/daniel-layon-07a613182/",
                "_blank",
                "noreferrer"
              )
            }
          ></Button>
          <Button
            type="default"
            shape="circle"
            icon={<GithubOutlined />}
            onClick={() =>
              window.open(
                "https://github.com/DanielLayon",
                "_blank",
                "noreferrer"
              )
            }
          ></Button>
          <Button
            type="default"
            shape="circle"
            icon={<WhatsAppOutlined />}
            onClick={() =>
              window.open(
                "https://github.com/DanielLayon",
                "_blank",
                "noreferrer"
              )
            }
          ></Button>
        </Flex>
      ),
    },
  ];

  const isMobile = () => (width < 750 ? true : false);
  return isMobile() ? (
    <section id="navbar-container">
      <nav id="navbar">
        <Flex
          gap="middle"
          align="start"
          style={{ width: "100%", height: "100%" }}
        >
          <Flex
            flex={5}
            gap={5}
            justify="flex-start"
            align="center"
            style={flexHeader}
          >
            <Avatar
              src={logo}
              shape="circle"
              style={{
                backgroundColor: "#ffffff",
                border: "solid 1px #000000",
                width: "2.5rem",
                height: "2.5rem",
              }}
            />
            <div
              style={{
                padding: 7,
              }}
            >
              <div
                style={{
                  fontFamily: "flowrise",
                  fontWeight: "bolder",
                  fontSize: "1.5rem",
                  color: "#000000",
                  fontStyle: "italic",
                }}
              >
                Layons
              </div>
            </div>
          </Flex>

          <Flex
            flex={1}
            justify="flex-end"
            align="center"
            gap={10}
            style={flexHeader}
          >
            <Dropdown
              menu={{ items: itemsMobile }}
              trigger={["click"]}
              placement="bottomLeft"
              arrow
            >
              <Avatar
                icon={<MenuOutlined />}
                onClick={(e) => e.preventDefault()}
                style={{ backgroundColor: "#8b03ff" }}
              />
            </Dropdown>
          </Flex>
        </Flex>
      </nav>
    </section>
  ) : (
    <section id="navbar-container">
      <nav id="navbar">
        <Flex
          gap="middle"
          align="start"
          style={{ width: "100%", height: "100%" }}
        >
          <Flex
            flex={5}
            gap={5}
            justify="flex-start"
            align="center"
            style={flexHeader}
          >
            <Avatar
              src={logo}
              shape="circle"
              style={{
                backgroundColor: "#ffffff",
                border: "solid 1px #000000",
                width: "2.5rem",
                height: "2.5rem",
              }}
            />
            <div
              style={{
                padding: 7,
              }}
            >
              <div
                style={{
                  fontFamily: "flowrise",
                  fontWeight: "bolder",
                  fontSize: "1.5rem",
                  color: "#000000",
                  fontStyle: "italic",
                }}
              >
                Layons
              </div>
            </div>
          </Flex>

          <Flex
            flex={1}
            justify="flex-end"
            align="center"
            gap={10}
            style={flexHeader}
          >
            <Button type="text" onClick={()=>goToContainer(<HomePage/>)}>
              Home
            </Button>
            <Button type="text" onClick={()=>goToContainer(<SobrePage/>)}>
              Sobre
            </Button>
            <Button type="text" onClick={()=>goToContainer(<ContatoPage/>)}>
              Contato
            </Button>

            <Flex gap={2}>
              <Button
                type="default"
                shape="circle"
                icon={<LinkedinOutlined />}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/daniel-layon-07a613182/",
                    "_blank",
                    "noreferrer"
                  )
                }
              ></Button>
              <Button
                type="default"
                shape="circle"
                icon={<GithubOutlined />}
                onClick={() =>
                  window.open(
                    "https://github.com/DanielLayon",
                    "_blank",
                    "noreferrer"
                  )
                }
              ></Button>
              <Button
                type="default"
                shape="circle"
                icon={<WhatsAppOutlined />}
                onClick={() =>
                  window.open(
                    "https://github.com/DanielLayon",
                    "_blank",
                    "noreferrer"
                  )
                }
              ></Button>
            </Flex>
          </Flex>
        </Flex>
      </nav>
    </section>
  );
}


