import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { FloatButton, ConfigProvider } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import "./style/index.css";
import LogoGather from "./components/layouts/header/HeaderAnimation";

const root = ReactDOM.createRoot(document.getElementById("root"));
const container = document.getElementById("root");

root.render(
  <ConfigProvider theme={{ token: { colorPrimary: "#8b03ff" } }}>
    <App />
    {/* <>
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{ right: 94 }}
        icon={<SettingOutlined />}
      >
        <FloatButton />
      </FloatButton.Group>
    </> */}
  </ConfigProvider>,
  container
);
